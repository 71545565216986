import config from '../config';
import useAxios from './useAxios';

export default function useGetReferences() {
    const { request: getTournamentCategories } = useAxios({
        method: 'get',
        url: `${config.server.serviceHostPublic}${config.server.apiPublic.tournamentCategories.uri}`
    });
    const { request: getSportCategories } = useAxios({
        method: 'get',
        url: `${config.server.serviceHostPublic}${config.server.apiPublic.sportCategories.uri}`
    });
    const { request: getCities } = useAxios({
        method: 'get',
        url: `${config.server.serviceHostPublic}${config.server.apiPublic.cities.uri}`
    });
    const { request: getRegions } = useAxios({
        method: 'get',
        url: `${config.server.serviceHostPublic}${config.server.apiPublic.regions.uri}`
    });
    const { request: getCountries } = useAxios({
        method: 'get',
        url: `${config.server.serviceHostPublic}${config.server.apiPublic.countries.uri}`
    });
    const { request: getClubs } = useAxios({
        method: 'get',
        url: `${config.server.serviceHostPublic}${config.server.apiPublic.clubs.uri}`
    });
    const { request: getCourtCoverages } = useAxios({
        method: 'get',
        url: `${config.server.serviceHostPublic}${config.server.apiPublic.courtCoverages.uri}`
    });
    const { request: getDirectors } = useAxios({
        method: 'get',
        url: `${config.server.serviceHostPublic}${config.server.apiPublic.directors.uri}`
    });

    const { request: getTournaments } = useAxios({
        method: 'get',
        url: `${config.server.serviceHost}${config.server.api.tournaments.uri}`
    });

    const { request: getTournamentsTags } = useAxios({
        method: 'get',
        url: `${config.server.serviceHostPublic}${config.server.api.tournamentsTags.uri}`
    });

    return {
        getTournamentCategories,
        getSportCategories,
        getCities,
        getRegions,
        getCountries,
        getClubs,
        getCourtCoverages,
        getDirectors,
        getTournaments,
        getTournamentsTags
    };
}
